import React, { useState, useEffect, Component } from "react"
import LayoutDoc from "../../components/layoutDoc"
import config from "../../utils/siteConfig"
import Button from "../../components/Atoms/Button/Button"
import Sidenav from '../../components/Organisms/Sidenav/Sidenav'
import { BLOCKS, INLINES, MARKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { injectIntl, Link, FormattedMessage } from "gatsby-plugin-intl"
import { faChevronRight, faDatabase, faPlay, faIndustry, faBuilding, faBookOpen, faCode, faCube } from "@fortawesome/free-solid-svg-icons"

export default function Docs({ data }) {
  debugger;
  const page = data.allContentfulZDocs.edges[0].node

  const Bold = ({ children }) => <span className="bold3">{children}</span>
  //const Text = ({ children }) => <p className="align-center">{children}</p>;

  const options = {
    renderNode: {
      [BLOCKS.HEADING_1]: (node, children) => (
        <h1 className="heading1">{children}</h1>
      ),
      [BLOCKS.HEADING_2]: (node, children) => (
        <h2 className="heading2">{children}</h2>
      ),
      [BLOCKS.HEADING_3]: (node, children) => (
        <h3 className="heading3b">{children}</h3>
      ),
      [BLOCKS.HEADING_4]: (node, children) => (
        <h4 className="bulletPoint">{children}</h4>
      ),
      [BLOCKS.EMBEDDED_ASSET]: (node, children) => (
        <img src={`https:${node.data.target.fields.file["en-US"].url}`} />
      ),
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <p className="d-inline-block">{children}</p>
      ),

    },
    renderMark: {
      [MARKS.BOLD]: text => <Bold>{text}</Bold>,
    },
    renderText: (text) =>
      text
        .replace(/\u2028/g, "")
        .split("\n")
        .flatMap((text, i) => [i > 0 && <br />, text])
  }

  //for responsiveness
  const [width, setWindowWidth] = useState(0)
  useEffect(() => {

    updateDimensions();

    window.addEventListener('resize', updateDimensions);
    return () =>
      window.removeEventListener('resize', updateDimensions);
  }, [])
  const updateDimensions = () => {
    const width = window.innerWidth
    setWindowWidth(width)
  }

  const responsive = {
    webContainer: width > 960
  }

  const {
    id,
    metaData,
    dcRtxt
  } = page

  return (
    <LayoutDoc
      IsHeaderImageDark={false}
      navColour={"white"}
      activeTab={"home"}
    >
      <head>
        <title>{metaData.title}</title>
        <meta charset="utf-8" />
        <meta name="description" content={metaData.description} />
        <meta name="keywords" content={metaData.keywords.keywords} />
        <meta name="robots" content="noindex" />
      </head>

      {/*Section Test*/}
      <section className="bg-white">
        <div className="doc-container bg-white">
          <div className="row align-items-center">

            <div className="col-lg-6 pb-5">
              <div className="row text-white pb-5">
                <p className="align-top h2-large text-darkgray">
                  {documentToReactComponents(dcRtxt.json, options)}
                </p>
              </div>
              <div className="row">

              </div>
              <div className="row pt-4">
                <div class="text-center">
                  <Button
                    className='btns'
                    buttonStyle='btn--primaryPurple'
                    buttonSize='btn--large2'
                    href={config.navigationURL_contactSales}
                  >
                    <FormattedMessage id="learn_more" />{" "}
                    <FontAwesomeIcon className="" size="1x" icon={faChevronRight} className="faChevronRight" />
                  </Button>
                </div>
              </div>
            </div>
            <div className="col-lg-6 pb-5 pl-4">
            </div>
          </div>
        </div>
      </section>

    </LayoutDoc>
  );
}



export const query = graphql`
query($locale: String!) {
    allContentfulZDocs(
        filter: {
        node_locale: { eq: $locale },
        id: {in:["4885b734-0fe3-5d24-b5fa-4c07641d8c5d", "4ac326ac-d4be-5b61-b9e1-508e0b7836d8"]}
    }) {
        edges {
            node {
                id
                metaData {
                    slug
                    title
                    description
                    keywords {
                        keywords
                    }
                }
                dcRtxt {
                  json
                }
                dcHHd
                dcRtxt2 {
                  json
                }
            }
        }
    }
}
`